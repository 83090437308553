<template>
  <div class="partner-custom-form">
    <invoice-address-form
      v-if="invoiceAddress"
      :partner-obj="partner"
      :action="action"
      @clearAction="action = null"
    />
  </div>
</template>

<script>
import InvoiceAddressForm from "./components/InvoiceAddressForm.vue"

export default {
  components: {
    InvoiceAddressForm
  },
  data() {
    return {
      action: null,
      partner: {
        givenName: "",
        familyName: "",
        companyName: "",
        additionalName: "",
        email: "",
        ico: "",
        dic: "",
        dphPayer: false,
        icdph: "",
        role: 'ROLE_PARTNER',
        address: {
          streetAddress: "",
          city: "",
          postalCode: "",
          country: ""
        }
      },
    };
  },
  methods: {
    submitPressed() {
      this.action = "add";
    },
  },
};
</script>
